import React from "react"
import PropTypes from "prop-types"

import { COLORS } from "../styles/constants"

const Footer = ({ siteTitle }) => (
  <footer
    style={{
      padding: "1rem",
      backgroundColor: COLORS.lightGray,
    }}
  >
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gridTemplateColumns: "repeat(auto-fit, minmax(200px, 240px))",
        padding: "1rem 2rem",
        fontSize: ".85rem",
      }}
    >
      <div style={{ color: COLORS.blue, fontWeight: 700 }}>
        <h4><strong>株式会社Barpro</strong></h4>
        <p style={{margin: 0}}>〒154-0012　東京都世田谷区駒沢5丁目18-4</p>
        <p style={{margin: 0}}>e-mail <a href="mailto:sales@barpro.co.jp">sales@barpro.co.jp</a></p>
        <p style={{margin: 0}}><a href="https://www.barpro.co.jp">www.barpro.co.jp</a></p>
        <div style={{ color: COLORS.gray }}>
          <p style={{margin: 0}}><a href="https://tanaoroshi.barpro.co.jp/Home/Privacy" target="_blank">プライバシーポリシー</a></p>
          <p style={{margin: 0}}><a href="https://tanaoroshi.barpro.co.jp/Home/Terms" target="_blank">利用規約</a></p>
        </div>    
      </div>
      
    </div>
    <div style={{ fontSize:"0.25rem", color: COLORS.gray, textAlign:"center" }}>
      © {new Date().getFullYear()}
      {` `}
      {siteTitle}
    </div>
  </footer>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
