export const COLORS = {
  lightWhite: "#FBFDFF",
  lightGray: "#F3F5F6",
  mediumGray: "#666",
  gray: "#666",
  babyBlue: "#E3EAF6",
  blue: "#567DBE",
}

export const GRADIENT = `linear-gradient(to top right, ${COLORS.babyBlue}, ${
  COLORS.blue
})`

export const BORDER_RADIUS = "10px"
